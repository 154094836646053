import { Component, Inject, OnInit } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from 'src/shared/app';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  sc: any;
  sh: any;

  constructor( @Inject(CONFIGURAZIONE) public configuration: Configurazione,
  @Inject(SERVIZI) public services: Services) { 
    this.sc = this.configuration.setup_configurazione;
    this.sh = this.configuration.setup_homepage;
  }

  ScrollTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
