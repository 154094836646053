export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyADsIfSwKX7sKBhmT-MqxccYC_LudYcPH0",
    authDomain: "michele-6-0.firebaseapp.com",
    databaseURL: "https://michele-6-0.firebaseio.com",
    projectId: "michele-6-0",
    storageBucket: "michele-6-0.appspot.com",
    messagingSenderId: "229554665612",
    appId: "1:229554665612:web:38e4396fa332f5fa7ea3a9",
    "measurementId": "G-SZXZX13T6W"
  }
};