import { Component, Inject, OnInit } from '@angular/core';
import moment from 'moment';
import { CONFIGURAZIONE, Configurazione, SetupFascia, SetupFasciaChiusura, SetupFasciaConsegna, SetupFasciaAsporto, Services, SERVIZI } from '../shared/app';

@Component({
    selector: 'app-orari',
    templateUrl: './orari.component.html'
})

export class OrariComponent implements OnInit {

    constructor(@Inject(CONFIGURAZIONE) public configuration: Configurazione, @Inject(SERVIZI) public services: Services) {
    }

    ngOnInit(): void { }

    Shift(a: number, b: number): number {
        return a << b;
    }
}
