import { Component, AfterViewInit, Inject } from '@angular/core';
import { MatAlert } from '@lhn/mat-alert';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html'
})
export class ProfiloComponent implements AfterViewInit {

  info: any = {}
  user: any = {};

  constructor(private alert: MatAlert, private functions: AngularFireFunctions, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
  @Inject(SERVIZI) public services: Services) {
    this.user = Object.assign({}, services.user);
  }

  async ngAfterViewInit() {
    var _info = await this.services.utenti.doc(this.services.user.uid).get().toPromise();
    if (_info.exists === false) {
      this.info = { uid: this.services.user.uid, sync: true };
      _info.ref.set(this.info);
    } else {
      this.info = _info.data();
    }
  }

  async Salva() {
    if (this.user.phoneNumber.startsWith('+') === false) {
      this.user.phoneNumber = `+39${this.user.phoneNumber}`;
    }
    await this.services.utenti.doc(this.services.user.uid).update(this.info);
    const fn = this.functions.httpsCallable('update_profile');
    await fn({
      phoneNumber: this.services.user.phoneNumber,
      displayName: this.services.user.displayName
    }).toPromise();
    this.alert.show('Profilo aggiornato con successo.');
  }
}
