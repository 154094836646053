import { Component, AfterContentInit, ViewEncapsulation, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-order1',
  templateUrl: './order.component.html',
  encapsulation: ViewEncapsulation.None
})

export class Order1Component implements AfterContentInit {

  public order: any = {};
  dMessageRef: any;
  dOrderRef: any;
  coid: any;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private snackbar: MatSnackBar,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.coid = this.route.snapshot.params['coid'];
    this.order = {};
  }

  ngAfterContentInit() {
    this.services.SelfLoad();
    this.dMessageRef = this.services.firestore.collection("messaggi").doc(this.coid);
    this.dOrderRef = this.services.firestore.collection("comanda_portata").doc(this.coid);
    this.dOrderRef.snapshotChanges().subscribe(_doc => {
      this.order = _doc.payload.data();
    });
    this.dMessageRef.set({ Command: "o" });
  }

  async Sync() {
    const question = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Inviare la richiesta di preconto?"
    });
    const confirm = await question.afterClosed().toPromise();
    if (!confirm) {
      return;
    }
    this.dMessageRef.set({ Command: "p" }).then(() => {
      this.snackbar.open(`Richiesta di preconto inviata`, null, {
        duration: 2000,
        horizontalPosition: "center",
        verticalPosition: "top"
      }).afterDismissed().subscribe(() => {
        this.services.prompt(false);
      });
    });
  }

}
