import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/home.component';
import { MenuComponent } from '../app/menu.component';
import { PrenotaComponent } from '../app/prenota.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { PrenotazioniComponent } from '../app/prenotazioni.component';
import { ProfiloComponent } from '../app/profilo.component';
import { PrivacyComponent } from '../app/privacy.component';
import { CarrelloComponent } from '../app/carrello.component';
import { AsportoComponent } from '../app/asporto.component';
import { AsportiComponent } from '../app/asporti.component';
import { ContattiComponent } from '../app/contatti.component';
import { Order1Component } from '../spa/order.component';
import { Menu1Component } from '../spa/menu.component';
import { ResumeComponent } from '../spa/resume.component';
import { FatturazioneComponent } from '../app/fatturazione.component';
import { AmministratorePrenotazioniComponent } from '../app/amministratore.prenotazioni.component';
import { UtenteCarrelloComponent } from '../app/utente.carrello.component';
import { PromoComponent } from 'src/app/promo.component';
import { OrariComponent } from 'src/app/orari.component';

const accedi_amministratore_prenotazioni = () => redirectUnauthorizedTo(['accedi', 'amministratore-prenotazioni']);
const accedi_asporti = () => redirectUnauthorizedTo(['accedi', 'asporti']);
const accedi_profilo = () => redirectUnauthorizedTo(['accedi', 'profilo']);
const accedi_fatturazione = () => redirectUnauthorizedTo(['accedi', 'fatturazione']);
const accedi_prenotazioni = () => redirectUnauthorizedTo(['accedi', 'prenotazioni']);
const profilo = () => redirectLoggedInTo(['profilo']);

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'amministratore-prenotazioni', component: AmministratorePrenotazioniComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accedi_amministratore_prenotazioni } },
  { path: 'asporto', component: AsportoComponent },
  { path: 'asporto/:mcid', component: AsportoComponent },
  { path: 'asporti', component: AsportiComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accedi_asporti } },
  { path: 'carrello', component: CarrelloComponent },
  { path: 'carrello/:cid', component: UtenteCarrelloComponent },
  { path: 'm/:coid', component: Menu1Component },
  { path: 'o/:coid', component: Order1Component },
  { path: 'r/:coid', component: ResumeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent},
  { path: 'menu', component: MenuComponent },
  { path: 'menu/:mcid', component: MenuComponent },
  { path: 'promo', component: PromoComponent },
  { path: 'prenota', component: PrenotaComponent },
  { path: 'profilo', component: ProfiloComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accedi_profilo } },
  { path: 'fatturazione', component: FatturazioneComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accedi_fatturazione } },
  { path: 'prenotazioni', component: PrenotazioniComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: accedi_prenotazioni } },
  { path: 'contatti', component: ContattiComponent },
  { path: 'orari', component: OrariComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
