import { Component, Inject, OnInit } from '@angular/core';
import { MatAlert } from '@lhn/mat-alert';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import * as moment from 'moment';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-prenota',
  templateUrl: './prenota.component.html'
})
export class PrenotaComponent implements OnInit {
  sc: any;
  token: string = null;
  closed: boolean;

  public Undo() {
    this.prn = {
      stato: 0,
      adulti: 1,
      bambini: 0,
      seggiolini: 0,
      celiaci: false,
      allergici: false
    };
    if (this.services.user) {
      this.prn.displayName = this.services.user.displayName;
      this.prn.email = this.services.user.email;
      this.prn.phoneNumber = this.services.user.phoneNumber;
      this.prn.uid = this.services.user.uid;
      return;
    }
    const utente = localStorage.getItem('utente');
    if (utente) {
      const _utente = JSON.parse(utente);
      this.prn.displayName = _utente.displayName;
      this.prn.email = _utente.email;
      this.prn.phoneNumber = _utente.phoneNumber;
    }
  }

  public stato: number = null;
  public aree: any[] = ['Interno', 'Esterno', 'Indifferente'];
  public zone: any = {};
  public fasce: any[] = [];
  public prn: any = {};
  public min_date;
  public max_date;

  constructor(private messaging: AngularFireMessaging,
    private alert: MatAlert,
    private router: Router,
    public dialog: MatDialog,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.Undo();
    this.sc = this.configuration.setup_configurazione;
    this.fasce = this.configuration.setup_fascia;

    this.min_date = moment().format('YYYY-MM-DD');
    this.max_date = moment().add(7, 'days').format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.messaging.requestToken.subscribe(token => {
      console.log('Token: ' + token);
      this.token = token;
    });
  }

  Data() {
    if (!this.fasce || !this.prn.data) {
      return;
    }
    this.closed = this.services.Chiuso(this.prn.data);
  }

  async Conferma() {
    if (this.prn.phoneNumber && this.prn.phoneNumber.startsWith('+') === false) {
      this.prn.phoneNumber = `+39${this.prn.phoneNumber}`;
    }
    const __prn = Object.assign({}, this.prn, { //
      ora: parseInt(this.prn.ora),
      token: this.token,
      sync: true,
      creazione: new Date(),
      uid: this.prn.uid || this.prn.phoneNumber || this.prn.email
    });
    const pid = `${__prn.uid}-${moment(this.prn.data).format('YYYYMMDD')}`;
    const prn = await this.services.prenotazioni.doc(pid).get().toPromise();
    if (prn.exists == true) {
      const question = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "Esiste una prenotazione per quella data. Sovrascrivere?"
      });
      const confirm = await question.afterClosed().toPromise();
      if (!confirm) {
        return;
      }
    }
    await this.services.prenotazioni.doc(pid).set(__prn);
    await this.alert.show('La prenotazione è stata creata', `Riceverà una email di conferma e/o un messaggio WhatsApp.`).toPromise();
    localStorage.setItem('utente', JSON.stringify({ displayName: __prn.displayName, email: __prn.email, phoneNumber: __prn.phoneNumber }));
    this.services.prompt(false);
    if (this.services.user) {
      await this.router.navigate(['prenotazioni']);
    } else {
      this.Undo();
    }
  }
}