import { Component, AfterContentInit, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { PhotoDialogComponent } from 'src/shared/photo-dialog.component';
import { CreaDialogComponent } from 'src/shared/crea-dialog.component';
import { _MatOptionBase } from '@angular/material/core';

@Component({
  selector: 'app-menu1',
  templateUrl: './menu.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class Menu1Component implements AfterContentInit {
  _mis: any;
  dMessageRef: any;
  coid: any;
  
  ngAfterContentInit(): void {
    this.mcs = this.configuration.menu_categoria.filter(_mc => _mc.EnableOnLine);
    this._mis = this.configuration.menu_ingrediente;
    this.services.SelfLoad();
  }

  async Foto(mp) {
    await this.dialog.open(PhotoDialogComponent, {
      hasBackdrop: false,
      panelClass: 'photo-panel',
      data: mp.Foto
    });
  }

  public mcs: any[] = [];
  mc: any = null;

  constructor(private route: ActivatedRoute, private dialog: MatDialog,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.coid = this.route.snapshot.params['coid'];
    this.dMessageRef = this.services.firestore.collection("messaggi").doc(this.coid);
  }

  Piatti(_mc) {
    const mps1 = this.configuration.self_mps.filter(_it => _it.Categoria == _mc.Guid && _it.Base !== true);
    const mps2 = this.configuration.self_mpcs.filter(_it => _it.Categoria == _mc.Guid);
    return mps2.concat(mps1);
  }

  Can() {
    if(!this.configuration.self_mpcs){
      return false;
    }
    if (this.configuration.self_mpcs.length > 0) {
      return true;
    }
    for (const _it of this.configuration.self_mps) {
      if (_it.Qta > 0) {
        return true;
      }
    }
    return false;
  }

  Plus(mp: any) {
    mp.Qta = (mp.Qta | 0) + 1;
    this.services.SelfSave();
  }

  Minus(mp: any) {
    mp.Qta = (mp.Qta | 0) - 1;
    if (mp.Qta === 0 && isNaN(mp.Id) === false) {
      this.configuration.self_mpcs.splice(mp.Id, 1);
    }
    this.services.SelfSave();
  }

  Qta(mc) {
    var qta1 = this.configuration.self_mps.filter(_it => _it.Categoria === mc.Guid && _it.Qta > 0).reduce((acc, cur) => acc + cur.Qta, 0);
    var qta2 = this.configuration.self_mpcs.filter(_it => _it.Categoria === mc.Guid && _it.Qta > 0).reduce((acc, cur) => acc + cur.Qta, 0);
    return qta1 + qta2 > 0 ? qta1 + qta2 : "";
  }

  async Crea(_mc) {
    const mps = this.configuration.menu_piatto.filter(_it => _it.EnableSelf && _it.Categoria === _mc.Guid && _it.Base === true);
    const _mcis = this.configuration.menu_categoria_ingrediente.filter(_it => _it.Categoria === _mc.Guid && this._mis.some(_mi => _mi.Guid === _it.Ingrediente && _mi.Base === true));
    const mcis = _mcis.map(mi => Object.assign(this.configuration.menu_ingrediente.filter(_it => _it.Guid === mi.Ingrediente)[0], { Prezzo: mi.Prezzo, Selected: false }));
    await this.dialog.open(CreaDialogComponent, {
      hasBackdrop: true,
      panelClass: 'crea-panel',
      height: '685px',
      data: {
        mps: mps,
        mcis: mcis,
        add: (_mpc) => {
          Object.assign(_mpc, { Id: this.configuration.self_mpcs.length });
          this.configuration.self_mpcs.push(_mpc);
          this.services.SelfSave();
        }
      }
    });
  }

  CanCrea(_mc) {
    if (!this._mis) {
      return false;
    }
    return this.configuration.self_mps.filter(_it => _it.Categoria == _mc.Guid && _it.Base === true).length > 0;
  }
}
