import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { AngularFireStorage } from '@angular/fire/storage';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from 'src/shared/login-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  sc: any;
  public show: boolean = false;
  
  public links = [];
  
  public logo: any;
  
  public external : boolean;
  
  constructor(private fireauth: AngularFireAuth, 
    private dialog: MatDialog,
    private router: Router, private storage: AngularFireStorage, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
      const ordini = !!this.configuration.setup_configurazione.Asporto || !!this.configuration.setup_configurazione.Consegna;
      const prenotazioni = !!this.configuration.setup_configurazione.Prenotazioni;
      this.sc = this.configuration.setup_configurazione;
      this.links = [
        { path: '/home', header: 'Home', show: () => true },
        { path: '/orari', header: 'Orari', show: () => this.areThereFasce() },
        { path: '/promo', header: 'Promozioni', show: () => true },
        { path: '/privacy', header: 'Privacy', show: () => true },
        { path: '/menu', header: 'Menu', show: () => true },
        { path: '/contatti', header: 'Contatti', show: () => true },
        { path: '/prenota', header: 'Prenota', show: () => prenotazioni },
        { path: '/asporto', header: 'Ordina', show: () => ordini, highlight : true },
        { path: '/prenotazioni', header: 'Le Mie Prenotazioni', show: () => this.services.user && prenotazioni },
        { path: '/asporti', header: 'I Miei Ordini', show: () => this.services.user && ordini },
        { path: '/carrello', header: 'Carrello', show: () => true && ordini },
        { path: '/profilo', header: 'Profilo', show: () => this.services.user },
        { path: '/fatturazione', header: 'Fatturazione', show: () => this.services.user },
        { path: '/amministratore-prenotazioni', header: 'Amministra Le Prenotazioni', show: () => this.services.user && this.services.user.uid === this.configuration.liid && prenotazioni }];
        if (this.sc) {
          this.logo = `https://storage.googleapis.com/${(<any>this.storage.storage.app.options).storageBucket}/${this.configuration.liid}/${this.sc.Foto}`;
        }
        router.events.subscribe(event => {
          if(event instanceof NavigationEnd ){
            const navigationEnd = <NavigationEnd>event;
            this.external = !(navigationEnd.url.startsWith('/m/') || navigationEnd.url.startsWith('/o/') || navigationEnd.url.startsWith('/menu'));
          }
        });
      }
      
      ngOnInit(): void {
        this.fireauth.authState.subscribe(() => {
          this.show = false;
        });
      }
      
      async Login() {
        await this.dialog.open(LoginDialogComponent, {
          width: '500px',
          data: "Sei sicuro?"
        }).afterClosed().toPromise();
      }
      
      Prompt() {
        this.services.prompt(true);
      }
      
      public Logout() {
        this.router.navigate(['home']).then(() => {
          this.fireauth.signOut();
        });
      }
      
      private areThereFasce(): boolean {
        let flag: boolean = (this.configuration.setup_fascia.length != 0);
        flag = flag || (this.configuration.setup_fascia_chiusura.length != 0);
        flag = flag || (this.configuration.setup_fascia_asporto.length != 0);
        flag = flag || (this.configuration.setup_fascia_consegna.length != 0);
        return flag;
      }
    }
    