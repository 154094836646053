import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore, DocumentData } from "@angular/fire/firestore";
//import { auth, firestore } from "firebase";
import { ICreateOrderRequest, ITransactionItem, IUnitAmount } from 'ngx-paypal';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { forkJoin } from 'rxjs';

export const CONFIGURAZIONE = new InjectionToken<string>('CONFIGURAZIONE');
export const SERVIZI = new InjectionToken<string>('SERVIZI');

export interface SetupConfigurazione {
  Provincia: string;
  Telefono: string;
  Foto: string;
  IdCodice: string;
  Info: string;
  Cognome?: any;
  Facebook: string;
  NumeroCivico: string;
  App1: string;
  Denominazione: string;
  CodiceFiscale?: any;
  CAP: string;
  Prenotazioni: boolean;
  Indirizzo: string;
  Comune: string;
  Nazione: string;
  Nome: string;
  PaypalClientId: string;
  Asporto: boolean;
  AsportoObbligoPagamento: boolean;
  Consegna: boolean;
  ConsegnaObbligoPagamento: boolean;
  ImpedisciDisdettaOrdini: boolean;
  ImportoMinimoConsegna?: number;
  ImportoMinimoAsporto?: number;
}

export interface MenuCategoria {
  Foto: string;
  Nome: string;
  EnableOnLine: boolean;
  Icon: string;
  Descrizione?: any;
  Guid: string;
  EnableSelf: boolean;
  Tipo: number;
  Homepage: boolean;
  Priorita: number;
}

export interface MenuCategoriaIngrediente {
  Categoria: string;
  Guid: string;
  Prezzo: number;
  Ingrediente: string;
}

export interface MenuPiatto {
  Surgelato: boolean;
  EnableSelf: boolean;
  Descrizione: string;
  Nome: string;
  Priorita: number;
  Allergeni: boolean;
  Colore: string;
  EnableOnLine: boolean;
  Esplodi: boolean;
  Guid: string;
  Base: boolean;
  Prezzo: number;
  PrezzoLibero: boolean;
  Icon?: any;
  Reparto: string;
  Foto: string;
  Homepage: boolean;
  Categoria: string;
  Favorite: boolean;
  Qta: number;
  MCIs: MenuIngrediente[];
}

export interface SetupFascia {
  Giorno: number;
  Guid: string;
  Durata: number;
  Inizio: number;
  Soglia?: any;
  Stato: number;
  Fine: number;
}

export interface SetupFasciaChiusura {
  DataDa: string;
  DataA: string;
  Giorno: number;
}

export interface SetupFasciaConsegna {
  Giorno: number;
  Inizio: number;
}

export interface SetupFasciaAsporto {
  Giorno: number;
  Inizio: number;
  Fine: number;
}
export interface SetupHomepage {
  IconBoxTitle4?: string;
  IconBoxText5?: string;
  IconBoxTitle6?: string;
  IconBoxTitle5?: string;
  IconBoxText3?: string;
  IconBoxTitle2?: string;
  IconBoxText4?: string;
  IconBoxText2?: string;
  IconBoxText6?: string;
  IconBoxTitle1?: string;
  IconBoxTitle3?: string;
  ChiSiamo?: string;
  IconBoxText1?: string;
}

export interface MenuIngrediente {
  Descrizione?: string;
  Tipo: number;
  Prezzo: number;
  Nome: string;
  Guid: string;
  Surgelato: boolean;
  Allergeni: boolean;
  Base: boolean;
}

export interface MenuPiattoIngrediente {
  Guid: string;
  Piatto: string;
  Ingrediente: string;
}

export interface SetupPromozioneCategoria {
  Promozione: string;
  Sync: boolean;
  Categoria: string;
  Guid: string;
}

export interface SetupPromozione {
  Descrizione: string;
  Nome: string;
  Sync: boolean;
  SpesaDa: number;
  ScontoI?: any;
  Servizio: number;
  SpesaA?: any;
  FasciaA: number;
  FasciaDa: number;
  ValidaDa: string;
  ScontoP: number;
  ValidaA?: any;
  Giorno: number;
  Guid: string;
  Simulation: number;
}

export interface Configurazione {
  liid: string;
  setup_configurazione: SetupConfigurazione;
  menu_categoria: MenuCategoria[];
  menu_categoria_ingrediente: MenuCategoriaIngrediente[];
  menu_piatto: MenuPiatto[];
  setup_fascia: SetupFascia[];
  setup_fascia_chiusura: SetupFasciaChiusura[];
  setup_fascia_consegna: SetupFasciaConsegna[];
  setup_fascia_asporto: SetupFasciaAsporto[];
  setup_homepage: SetupHomepage;
  menu_ingrediente: MenuIngrediente[];
  menu_piatto_ingrediente: MenuPiattoIngrediente[];
  setup_promozione: SetupPromozione[];
  setup_promozione_categoria: SetupPromozioneCategoria[];
  self_mps: any[];
  self_mpcs: any[];
}

@Injectable()
export class Services {

  constructor(@Inject(CONFIGURAZIONE) private configurazione: Configurazione, firestore: AngularFirestore, private authentication: AngularFireAuth) {
    this.firestore = firestore.collection("clienti").doc(this.configurazione.liid);
    this.utenti = this.firestore.collection('utenti');
    this.contatti = this.firestore.collection('contatti');
    this.prenotazioni = this.firestore.collection('prenotazioni');
  }

  guid1: string = '00000001-0000-0000-0000-000000000000';
  guid2: string = '00000002-0000-0000-0000-000000000000';
  guid3: string = '00000003-0000-0000-0000-000000000000';
  guid4: string = '00000004-0000-0000-0000-000000000000';
  prompt: (boolean) => void;
  canPrompt: () => boolean;
  contatti: AngularFirestoreCollection<DocumentData>;
  user: firebase.User;
  prenotazioni: AngularFirestoreCollection<DocumentData>;
  firestore: AngularFirestoreDocument;
  utenti: AngularFirestoreCollection;

  public Phone() {
  }

  public Chiuso(date: string): boolean {
    const weekDay = moment(date).weekday();
    const sfcs = this.configurazione.setup_fascia_chiusura;
    console.log(`Trovate ${sfcs.length} fasce chiusura`);
    //const sfcs1 = sfcs.filter(_sf => !_sf.DataA && date >= _sf.DataDa && (!_sf.Giorno || (_sf.Giorno & (1 << (weekDay))) > 0));
    //console.log(`Trovate ${sfcs1.length} fasce chiusura (Giorno)`);
    const sfcs2 = sfcs.filter(_sf => !_sf.Giorno && ((date >= _sf.DataDa && date <= _sf.DataA) || (date == _sf.DataDa && !_sf.DataA)));
    console.log(`Trovate ${sfcs2.length} fasce chiusura (Data)`);
    const sfcs3 = sfcs.filter(_sf => ((_sf.Giorno & (1 << (weekDay))) > 0) && ((date >= _sf.DataDa && date <= _sf.DataA) || (date == _sf.DataDa && !_sf.DataA)));
    console.log(`Trovate ${sfcs3.length} fasce chiusura (Data e Giorno)`);
    return (/*sfcs1.length + */sfcs2.length + sfcs3.length) > 0;
  }

  Giorno(g) {
    if (g === 0)
      return 'Tutti i giorni';
    var text = '';
    var days = ['DOM', 'LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB']
    for (var _it = 0; _it < days.length; _it++) {
      if (g & (1 << _it)) {
        text = `${text} ${days[_it]}`;
      }
    }
    return text;
  }

  GiornoPrenotazione(g) {
    var days = ['DOMENICA', 'LUNEDI', 'MARTEDI', 'MERCOLEDI', 'GIOVEDI', 'VENERDI', 'SABATO']
    return days[g];
  }

  Ora(o) {
    return `${Math.floor(o / 60).toString().padStart(2, '0')}:${(o % 60).toString().padStart(2, '0')}`;
  }

  Data(d) {
    return `${moment(d).format('DD/MM/YYYY')}`;
  }

  Ingredienti(_mp) {
    if(_mp.Descrizione){
      return _mp.Descrizione;
    }
    var mis1 = this.configurazione.menu_piatto_ingrediente.filter(_it => _it.Piatto == _mp.Guid).map(_it => _it.Ingrediente);
    if (_mp.MCIs) {
      mis1 = mis1.concat(_mp.MCIs.map(_it => _it.Guid));
    }
    const mis2 = mis1.map(_it => {
      var nome = this.configurazione.menu_ingrediente.find(__it => __it.Guid == _it).Nome;
      return nome;
    });
    if (mis2.length) {
      var str = mis2.join(', ');
      return str;
    }
    return _mp.Descrizione;
  }

  Prezzo(_mp: MenuPiatto): number {
    var _pu = _mp.Prezzo;
    if (_mp.MCIs && _mp.MCIs.length) {
      _pu += _mp.MCIs.map(_it => {
        return this.configurazione.menu_ingrediente.find(__it => __it.Guid == _it.Guid).Prezzo;
      }).reduce((_ac, _it) => _ac + _it);
    }
    return _pu;
  }

  Apply(_sp: SetupPromozione, _co: any) {
    var sco = this.Simulate(_sp, _co);
    _co.promozione = _sp.Guid;
    _co.comanda_portate.push(<MenuPiatto>{
      Guid: this.guid4,
      Nome: _sp.Nome,
      Descrizione: _sp.Descrizione || '',
      Qta: 1,
      Prezzo: -sco
    });
  }

  Simulate(_sp: SetupPromozione, _co: any) {
    const __cps = _co.comanda_portate.filter(_it => {
      var cnt = this.configurazione.setup_promozione_categoria.filter(__it => __it.Promozione === _sp.Guid);
      var ctg = this.configurazione.setup_promozione_categoria.filter(__it => __it.Promozione === _sp.Guid && __it.Categoria === _it.Categoria);
      return ctg.length > 0 || cnt.length === 0;
    }).map(_it => {
      return _it.Qta * this.Prezzo(_it);
    });
    const tot = ((__cps && __cps.length) ? __cps.reduce((a, b) => a + b) : 0);
    return _sp.ScontoP * tot / 100;
  }

  Totale(_mps: MenuPiatto[]): number {
    const __mps = _mps.map(_it => {
      return _it.Qta * this.Prezzo(_it);
    });
    return ((__mps && __mps.length) ? __mps.reduce((a, b) => a + b) : 0);
  }

  Spesa(_mps: MenuPiatto[]): number {
    const __mps = _mps.filter(_it => _it.Guid !== this.guid4).map(_it => {
      return _it.Qta * this.Prezzo(_it);
    });
    return ((__mps && __mps.length) ? __mps.reduce((a, b) => a + b) : 0);
  }

  Sconti(_mps: MenuPiatto[]): number {
    const __mps = _mps.filter(_it => _it.Guid === this.guid4).map(_it => {
      return _it.Qta * this.Prezzo(_it);
    });
    return ((__mps && __mps.length) ? __mps.reduce((a, b) => a + b) : 0);
  }

  Reset(_co: any) {
    _co.promozione = null;
    _co.comanda_portate = _co.comanda_portate.filter(_it => _it.Guid !== this.guid4);
  }

  async Promozione(_co: any) {
    this.Reset(_co);
    var data = moment(_co.data).format('YYYY-MM-DD');
    var weekDay = moment(_co.data).day();
    var service = _co.Consegna ? 0 : 1;
    var ora = parseInt(`${_co.ora}`);
    if (!data || !ora) {
      return null;
    }
    var sps = this.configurazione.setup_promozione;
    console.log(`Trovate: ${sps.length} promozioni.`)
    sps = sps.filter(_it => (_it.Giorno & (1 << (weekDay))) > 0);
    console.log(`Trovate: ${sps.length} promozioni per giorno.`)
    sps = sps.filter(_it => (_it.Servizio & (1 << (service))) > 0);
    console.log(`Trovate: ${sps.length} promozioni per servizio.`)
    sps = sps.filter(_it => ora >= _it.FasciaDa && ora <= _it.FasciaA);
    console.log(`Trovate: ${sps.length} promozioni per fascia.`)
    sps = sps.filter(_it => data >= _it.ValidaDa && (_it.ValidaA == null || data <= _it.ValidaA));
    console.log(`Trovate: ${sps.length} promozioni per data.`);
    var sp = this.Totale(_co.comanda_portate);
    sps = sps.filter(_it => sp >= _it.SpesaDa && (_it.SpesaA == null || sp <= _it.SpesaA));
    console.log(`Trovate: ${sps.length} promozioni per spesa.`);
    if (sps.length === 0) {
      return;
    }
    for (const _it of sps) {
      _it.Simulation = this.Simulate(_it, _co);
    }
    const _sp = sps.reduce(function (acc, cur) {
      return (cur.Simulation > acc.Simulation ? acc : cur);
    });
    this.Apply(_sp, _co);
  }

  SelfLoad() {
    if ((this.configurazione.self_mpcs && this.configurazione.self_mpcs.length > 0) //
      || (this.configurazione.self_mps && this.configurazione.self_mps.filter(m => m.Qta).length > 0)) {
      return;
    }
    const so = localStorage.getItem('so');
    if (!so) {
      this.configurazione.self_mps = this.configurazione.menu_piatto.filter(_mc => _mc.EnableOnLine);
      this.configurazione.self_mpcs = this.configurazione.self_mpcs || [];
      return;
    }
    const sobj = JSON.parse(so);
    this.configurazione.self_mpcs = sobj.self_mpcs;
    this.configurazione.self_mps = sobj.self_mps;
  }

  SelfSave() {
    const sobj = {
      self_mpcs: this.configurazione.self_mpcs,
      self_mps: this.configurazione.self_mps
    };
    const so = JSON.stringify(sobj);
    localStorage.setItem('so', so);
  }

  SelfClean() {
    localStorage.removeItem('so');
    this.configurazione.self_mps = this.configurazione.menu_piatto.filter(_mc => _mc.EnableOnLine);
    for (const iterator of this.configurazione.self_mps) {
      iterator.Qta = null;
    }
    this.configurazione.self_mpcs = [];
  }

  PayPalOrder(co: any): ICreateOrderRequest {
    return {
      intent: 'CAPTURE',
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: `${this.Totale(co.comanda_portate)}`,
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: `${this.Spesa(co.comanda_portate)}`
            },
            discount: {
              currency_code: 'EUR',
              value: `${-1 * this.Sconti(co.comanda_portate)}`
            }
          }
        },
        items: co.comanda_portate.filter(_it => _it.Prezzo > 0).map(_it => <ITransactionItem>{
          name: _it.Nome,
          quantity: `${_it.Qta}`,
          category: 'PHYSICAL_GOODS',
          unit_amount: {
            currency_code: 'EUR',
            value: `${this.Prezzo(_it)}`,
          },
        })
      }]
    }
  };
}