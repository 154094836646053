import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatAlert } from '@lhn/mat-alert';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import * as moment from 'moment';

@Component({
  selector: 'app-prenotazioni',
  templateUrl: './prenotazioni.component.html'
})
export class PrenotazioniComponent {

  public prns: any[] = [];
  public status = ['In attesa di conferma', 'Confermata', 'Annullata dal gestore', 'Annullata dal cliente'];

  constructor(private alert: MatAlert, private dialog: MatDialog, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    if (this.services.user) {
      const uids = [];
      if (this.services.user.uid) {
        uids.push(this.services.user.uid);
      }
      if (this.services.user.phoneNumber) {
        uids.push(this.services.user.phoneNumber);
      }
      if (this.services.user.email) {
        uids.push(this.services.user.email);
      }
      if(uids.length == 0){
        return;
      }
      var t = moment().localeData();
      this.services.firestore.collection("prenotazioni", ref => ref.where("uid", "in", uids).orderBy('creazione', 'desc')).get().subscribe(_prns => {
        this.prns = _prns.docs.map(_prn => Object.assign(<any>_prn.data(), {
          id: _prn.id,
          data: moment(<any>_prn.data().data).lang('IT').format('DD/MM/YYYY ddd'),
          creazione: new Date((<any>_prn.data()).creazione.seconds * 1000)
        }));
      });
    } else {
      this.prns = [];
    }
  }

  async Annulla(prn) {
    const question = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Sei sicuro?"
    });
    const confirm = await question.afterClosed().toPromise();
    if (confirm) {
      this.services.prenotazioni.doc(prn.id).update({ stato: 3, sync: true }).then(() => {
        this.alert.show('La prenotazione è stata annullata', `Riceverà una conferma via email o WhatsApp.`);
      });
    }
  }
}
