import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  template: '<app-header></app-header><router-outlet></router-outlet><app-footer></app-footer>'
})
export class AppComponent {

  constructor(swUpdate: SwUpdate,
    snackbar: MatSnackBar) {
    swUpdate.available.subscribe(() => {
      snackbar.open(`Nuova versione`, null, {
        duration: 2000,
        horizontalPosition: "center",
        verticalPosition: "top"
      }).afterDismissed().subscribe(() => {
        window.location.reload();
      });
    });
  }
}