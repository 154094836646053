import { Component, AfterContentInit, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI } from '../shared/app';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent } from 'src/shared/ok-dialog.component';
import { _MatOptionBase } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timeout } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/shared/confirmation-dialog.component';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  animations: [
    trigger(
      'inOutAnimation1',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class ResumeComponent implements AfterContentInit {
  notes: string;
  dMessageRef: any;
  coid: any;

  ngAfterContentInit(): void {
    this.services.SelfLoad();
  }

  constructor(private route: ActivatedRoute, private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private router: Router,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) {
    this.coid = this.route.snapshot.params['coid'];
    this.dMessageRef = this.services.firestore.collection("messaggi").doc(this.coid);
  }

  async Clean(){
    const question = await this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Eliminare tutte le portate?"
    }).afterClosed().toPromise();
    if(!question){
      return;
    }
    this.services.SelfClean();
  }


  Piatti() {
    const mps1 = this.configuration.self_mps.filter(_it => _it.Qta > 0);
    const mps2 = this.configuration.self_mpcs.filter(_it => _it.Qta > 0);
    return mps2.concat(mps1);
  }

  async Sync() {
    const mps1 = this.configuration.self_mps.filter(_it => _it.Qta > 0);
    const mps2 = this.configuration.self_mpcs.filter(_it => _it.Qta > 0);
    const mps = mps2.concat(mps1);
    await this.dMessageRef.set({ Command: "n", Lines: mps, Notes: this.notes || '' });
    /*
    for (const _it of this.configuration.self_mps) {
      _it.Qta = 0;
    }
    this.configuration.self_mpcs = [];
    */
    await this.snackbar.open(`Il tuo ordine è stato inviato.`, null, {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "top"
    }).afterDismissed().toPromise();
    this.services.SelfClean();
    //this.services.SelfClean();
    /*
    setTimeout(() => {
      this.services.prompt(false);
    }, 5000);
    */
    await this.router.navigate(['o', this.coid]);
  }
}
