import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services } from 'src/shared/app';

@Component({
  selector: 'app-create-amministratore-prenotazioni-component',
  templateUrl: './create.amministratore.prenotazioni.component.html'
})
export class CreateAmministratorePrenotazioniComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateAmministratorePrenotazioniComponent>,
    @Inject(MAT_DIALOG_DATA) public prn: any, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    @Inject(SERVIZI) public services: Services) { }

  public Close(result : boolean) {
    this.dialogRef.close(result);
  }
}