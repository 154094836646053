import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatAlert } from '@lhn/mat-alert';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CONFIGURAZIONE, Configurazione, SERVIZI, Services, SetupConfigurazione } from 'src/shared/app';
import { User } from '@firebase/auth-types';

@Component({
  selector: 'app-asporti',
  templateUrl: './asporti.component.html'
})
export class AsportiComponent {

  public user: User = null;
  public orders: any[] = [];
  public status = ['In attesa di conferma', 'Confermata', 'Annullata dal gestore', 'Annullata dal cliente', 'In preparazione', 'Pronto'];
  public sc: SetupConfigurazione;

  constructor(private fireauth: AngularFireAuth, private alert: MatAlert, private dialog: MatDialog, @Inject(CONFIGURAZIONE) public configuration: Configurazione,
  @Inject(SERVIZI) public services: Services) {
    this.sc = configuration.setup_configurazione;
    this.fireauth.authState.subscribe(user => {
      this.user = user;
      if(this.user){
        services.firestore.collection("carrelli", ref => ref.where("uid", "==", user.uid).orderBy('creazione', 'desc')).get().subscribe(_orders => {
          this.orders = _orders.docs.map(_order => Object.assign(<any>_order.data(), {
            id: _order.id,
            creazione: new Date((<any>_order.data()).creazione.seconds * 1000)
          }));
        });  
      }
    });
  }

  Annulla(order) {
    const question = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Sei sicuro?"
    });
    question.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.services.firestore.collection("carrelli").doc(order.id).update({ stato: 3, sync: true }).then(() => {
          this.alert.show('L\'ordine di asporto è stato annullato', `Riceverà una email di conferma a questo indirizzo ${this.user.email}.`);
        });
      }
    });
  }
}
