import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatAlert } from '@lhn/mat-alert';
import { Router } from '@angular/router';
import { CONFIGURAZIONE, Configurazione, Services, SERVIZI, SetupConfigurazione } from '../shared/app';
import { IPayPalConfig } from 'ngx-paypal';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from 'src/shared/login-dialog.component';
import moment from 'moment';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-carrello',
  templateUrl: './carrello.component.html'
})
export class CarrelloComponent implements OnInit {
  guid1: string = '00000001-0000-0000-0000-000000000000';
  guid2: string = '00000002-0000-0000-0000-000000000000';
  guid3: string = '00000003-0000-0000-0000-000000000000';
  guid4: string = '00000004-0000-0000-0000-000000000000';
  PayPalConfig?: IPayPalConfig;
  closed: boolean = false;
  tmpCart: any = { comanda_portate: [], consegna: false };
  fasce: any[] = [];
  sc: SetupConfigurazione;
  utente: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>;
  token: string;
  position: Position;
  public min_date;
  public max_date;

  async Login() {
    await this.dialog.open(LoginDialogComponent, {
      width: '500px',
      data: "Sei sicuro?"
    }).afterClosed().toPromise();
  }

  constructor(private fireauth: AngularFireAuth,
    private messaging: AngularFireMessaging,
    private alert: MatAlert,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    @Inject(CONFIGURAZIONE) public configuration: Configurazione,
    private dialog: MatDialog,
    @Inject(SERVIZI) public services: Services) {
    this.fireauth.authState.subscribe(async user => {
      if (user && this.tmpCart) {
        this.tmpCart.displayName = this.services.user.displayName;
        this.tmpCart.email = this.services.user.email;
        this.tmpCart.phoneNumber = this.services.user.phoneNumber;
        this.tmpCart.uid = user.uid;
        this.utente = await this.services.utenti.doc(this.services.user.uid).get().toPromise();
      }
      if (!user) {
        this.utente = null;
      }
      this.cdRef.detectChanges();
    });
    this.sc = this.configuration.setup_configurazione;

    this.min_date = moment().format('YYYY-MM-DD');
    this.max_date = moment().add(7, 'days').format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.messaging.requestToken.subscribe(token => {
      console.log('Token: ' + token);
      this.token = token;
    });
    if (this.sc.PaypalClientId) {
      this.PayPalConfig = {
        currency: 'EUR',
        clientId: this.sc.PaypalClientId,
        createOrderOnClient: () => {
          this.Prepare();
          return this.services.PayPalOrder(this.tmpCart);
        },
        advanced: {
          commit: 'true'
        },
        style: {
          label: "checkout",
          layout: 'vertical',
          shape: "rect",
          size: "large"
        },
        onClientAuthorization: async (_auth) => {
          Object.assign(this.tmpCart, {
            paypalId: _auth.id,
            paypalCreationDate: _auth.create_time,
            paypalPayerId: _auth.payer.payer_id,
            paypalEmailAddress: _auth.payer.email_address
          });
          await this.Finalize();
        }
      };
      this.tmpCart.consegna = false;
    }
    const carrello = localStorage.getItem('carrello');
    if (carrello) {
      this.tmpCart = JSON.parse(carrello);
    } else {
      this.tmpCart = { comanda_portate: [], consegna: false };
    }
    this.services.Reset(this.tmpCart);
    this.Data();
    this.Fasce();
    this.Validate();
  }

  Gps() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.position = position;
        console.log(position);
      }, () => {
        this.position = null;
      }, {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0
      });
    }
  }

  Validate(){
    const tot = this.services.Totale(this.tmpCart.comanda_portate);
    this.tmpCart.totale = tot;
    const min = this.tmpCart.consegna ? this.sc.ImportoMinimoConsegna : this.sc.ImportoMinimoAsporto;
    this.tmpCart.valido = !min || tot >= min;
  }

  Fasce() {
    if (this.tmpCart.consegna) {
      this.Gps();
      console.log(`Consegna`);
      this.fasce = this.configuration.setup_fascia_consegna;
    } else {
      console.log(`Asporto`);
      this.fasce = this.configuration.setup_fascia_asporto;
    }
    this.tmpCart.ora = null;
  }

  Consegna() {
    this.Fasce();
    this.services.Promozione(this.tmpCart);
    this.tmpCart.consegnaIndirizzo =
      this.tmpCart.consegnaNumeroCivico =
      this.tmpCart.consegnaCap =
      this.tmpCart.consegnaComune =
      this.tmpCart.consegnaLatitude =
      this.tmpCart.consegnaLongitude = null;
    if (this.tmpCart.consegna && this.utente && this.utente.exists) {
      var infoConsegna = this.utente.data();
      this.tmpCart.consegnaIndirizzo = infoConsegna.consegnaIndirizzo;
      this.tmpCart.consegnaNumeroCivico = infoConsegna.consegnaNumeroCivico;
      this.tmpCart.consegnaCap = infoConsegna.consegnaCap;
      this.tmpCart.consegnaComune = infoConsegna.consegnaComune;
      this.tmpCart.consegnaLatitude = infoConsegna.consegnaLatitude;
      this.tmpCart.consegnaLongitude = infoConsegna.consegnaLongitude;
      this.Save();
    };
    this.Validate();
  }

  async Ora() {
    await this.services.Promozione(this.tmpCart);
  }

  async Data() {
    if (!this.tmpCart.data) {
      await this.services.Promozione(this.tmpCart);
      this.Save();
      return;
    }
    this.closed = this.services.Chiuso(this.tmpCart.data);
    await this.services.Promozione(this.tmpCart);
    this.Save();
  }

  async PortataRimuovi(_mp) {
    const comanda_portate = <any[]>this.tmpCart.comanda_portate;
    const idx = comanda_portate.findIndex(_it => _it.Guid === _mp.Guid);
    if (idx < 0) {
      return;
    }
    var dst = comanda_portate.find(_it => _it.Guid === _mp.Guid);
    if (dst.Qta > 1) {
      dst.Qta = dst.Qta - 1;
    } else {
      comanda_portate.splice(idx, 1);
    }
    await this.services.Promozione(this.tmpCart);
    this.Save();
  }

  Portate() {
    return this.tmpCart.comanda_portate.filter(_it => _it.Guid !== this.guid4);
  }

  Sconti() {
    return this.tmpCart.comanda_portate.filter(_it => _it.Guid === this.guid4);
  }

  Prepare(): void {
    const tmpCart = Object.entries(this.tmpCart).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    this.tmpCart = Object.assign(tmpCart, {
      ora: parseInt(this.tmpCart.ora),
      token: this.token || '',
      stato: 0,
      sync: true
    });
  }

  Save() {
    localStorage.setItem('carrello', JSON.stringify(this.tmpCart));
  }

  ConsegnaToggle() {
    this.tmpCart.consegna = !this.tmpCart.consegna;
    this.Consegna();
  }

  async Finalize() {
    const email = this.tmpCart.email;
    this.tmpCart.creazione = this.tmpCart.creazione || new Date();
    if (this.tmpCart.phoneNumber && this.tmpCart.phoneNumber.startsWith('+') === false) {
      this.tmpCart.phoneNumber = `+39${this.tmpCart.phoneNumber}`;
    }
    if(this.position && this.position.coords){
      this.tmpCart.consegnaLatitude = `${this.position.coords.latitude}`;
      this.tmpCart.consegnaLongitude = `${this.position.coords.longitude}`;
    }
    /**/
    if (!this.tmpCart.creazione || !this.tmpCart.data || !this.tmpCart.ora || !this.tmpCart.uid) {
      return;
    }
    const cid = `${moment(this.tmpCart.creazione).format('YYYY-MM-DD-HH-mm-ss')}-${this.tmpCart.uid}`;
    console.log(`Creo ordine: ${cid}`);
    await this.services.firestore.collection("carrelli").doc(cid).set(this.tmpCart);
    if (this.tmpCart.consegna) {
      const info = {
        uid: this.services.user.uid,
        consegnaIndirizzo: this.tmpCart.consegnaIndirizzo,
        consegnaNumeroCivico: this.tmpCart.consegnaNumeroCivico,
        consegnaCap: this.tmpCart.consegnaCap,
        consegnaComune: this.tmpCart.consegnaComune,
      };
      if (this.utente && this.utente.exists) {
        Object.assign(this.utente.data(), info);
      }
      await this.services.utenti.doc(this.services.user.uid).set(info);
    }
    await this.Svuota();
    await this.router.navigate(['asporti']);
    await this.alert.show('L\'ordine di asporto è stato presa in carico', `Riceverà una email di conferma a questo indirizzo ${email}.`).toPromise();
    this.services.prompt(true);
  }

  async Invia() {
    this.Prepare();
    await this.Finalize();
  }

  async Svuota() {
    this.tmpCart = { comanda_portate: [], consegna: false };
    localStorage.removeItem('carrello');
  }
}
