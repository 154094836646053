import { Component, Inject } from '@angular/core';
import { CONFIGURAZIONE, Configurazione, MenuCategoria, SetupPromozione, SetupPromozioneCategoria } from '../shared/app';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
})
export class PromoComponent {
  sps: SetupPromozione[];
  spcs: SetupPromozioneCategoria[];
  mcs: MenuCategoria[];
  constructor(@Inject(CONFIGURAZIONE) public configuration: Configurazione) {
    this.mcs = this.configuration.menu_categoria;
    this.sps = this.configuration.setup_promozione;
    this.spcs = this.configuration.setup_promozione_categoria;
  }

  Categorie(_sp) {
    const mis = this.spcs.filter(_it => _it.Promozione == _sp.Guid).map(_it => this.mcs.find(__it => __it.Guid == _it.Categoria)).filter(_it => _it).map(_it => _it.Nome);
    if(mis.length === 0){
      return '';
    }
    return mis.reduce((acc, cur) => `${acc}, ${cur}`);
  }
}